<template>
  <v-card v-if="recycler" class="table-page">
    <div class="px-10 pt-5 mb-6">
      <h2>Reciclador</h2>
      <label-text
        label="Empresa:"
        :labelColProps="{ cols: 5, md: 4 }"
        :text="recycler.name"
        :textColProps="{ cols: 7, md: 8 }"
      />
      <label-text
        label="CNPJ:"
        :labelColProps="{ cols: 5, md: 4 }"
        :text="recycler.cnpj | VMask(masks.cnpj)"
        :textColProps="{ cols: 7, md: 8 }"
      />
      <label-text
        v-if="address"
        label="Endereço:"
        :labelColProps="{ cols: 5, md: 4 }"
        :text="address"
        :textColProps="{ cols: 7, md: 8 }"
      />
    </div>
    <v-divider />
    <div class="px-10 pt-5 mb-6">
      <h2>Representante</h2>
      <label-text
        label="Nome:"
        :labelColProps="{ cols: 5, md: 4 }"
        :text="recycler.recyclerAgent"
        :textColProps="{ cols: 7, md: 8 }"
      />
      <label-text
        label="E-mail:"
        :labelColProps="{ cols: 5, md: 4 }"
        :text="recycler.email"
        :textColProps="{ cols: 7, md: 8 }"
      />
      <label-text
        v-if="phones"
        label="Telefone(s):"
        :labelColProps="{ cols: 5, md: 4 }"
        :text="phones"
        :textColProps="{ cols: 7, md: 8 }"
      />
    </div>
  </v-card>
</template>

<script>
  import MasksMixin from "@/modules/core/mixins/masksMixin";
  import { parseAdressSingleLine } from "@/modules/core/helpers/addressHelpers";

  import LabelText from "@/modules/core/views/components/LabelText.vue";
  export default {
    name: "InfoRecycler",
    components: {
      LabelText
    },
    mixins: [MasksMixin],
    props: {
      recycler: {
        type: Object,
        required: true
      }
    },
    computed: {
      phones() {
        const { phones } = this.recycler;
        if (!phones) return "";
        return phones.map(phone => this.parsePhone(phone)).join(", ");
      },
      address() {
        const { address } = this.recycler;
        if (!address) return "";
        return parseAdressSingleLine(address);
      }
    }
  };
</script>

<style lang="scss" scoped></style>
