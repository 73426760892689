<template>
  <v-row>
    <v-col v-bind="{ ...$attrs, ...labelColProps }">
      <span class="black-3--text">{{ label }}</span>
    </v-col>
    <v-col v-bind="{ ...$attrs, ...textColProps }">
      <span class="black-1--text">{{ text }}</span>
    </v-col>
  </v-row>
</template>

<script>
  export default {
    name: "LabelText",
    props: {
      label: {
        type: String,
        required: true
      },
      labelColProps: {
        type: Object,
        default: () => ({})
      },
      text: {
        type: String,
        required: true
      },
      textColProps: {
        type: Object,
        default: () => ({})
      }
    }
  };
</script>

<style lang="scss" scoped></style>
