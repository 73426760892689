<template>
  <v-card class="table-page">
    <template v-if="destinations.length">
      <h2 class="pl-5 pt-5 mb-6">Destinações</h2>
      <v-divider />
      <div class="d-flex flex-column justify-space-between content">
        <v-card-text class="pa-0">
          <v-data-table
            hide-default-footer
            :items="destinations"
            :headers="headers"
            :server-items-length="tableInfo.totalItems"
            :options.sync="dataTableOptions"
          >
            <template v-slot:item.createdAt="{ item }">
              <template>
                {{ item.createdAt | formatDateTime }}
              </template>
            </template>
          </v-data-table>
        </v-card-text>
        <v-card-actions class="d-flex flex-row justify-center">
          <pagination
            :currentPage="tableInfo.currentPage"
            :totalPages="tableInfo.totalPages"
            :itemsPerPage="tableInfo.itemsPerPage"
            @currentPageChanged="handleCurrentPageChange($event)"
            @itemsPerPageChanged="handleItemsPerPageChange($event)"
          />
        </v-card-actions>
      </div>
    </template>
    <template v-else>
      <div class="inherit-height d-flex justify-content-center">
        <data-not-found
          title="Nenhuma destinação foi encontrada!"
          :imgSrc="require('@/assets/residue-inventory.svg')"
        />
      </div>
    </template>
  </v-card>
</template>

<script>
  import { mapActions } from "vuex";

  import DataNotFound from "@/modules/core/views/components/DataNotFound.vue";
  import Pagination from "@/modules/core/views/components/Pagination";

  import MasksMixin from "@/modules/core/mixins/masksMixin";
  import TableHandler from "@/modules/core/mixins/tableHandler";

  import { format } from "date-fns";

  export default {
    name: "ListDestination",
    components: {
      DataNotFound,
      Pagination
    },
    props: {
      id: {
        type: Number,
        required: true
      }
    },
    mixins: [MasksMixin, TableHandler],
    data: () => ({
      destinations: [],
      headers: [
        {
          text: "Data destinação",
          value: "createdAt"
        },
        {
          text: "CRC de origem",
          value: "crcDescription"
        },
        {
          text: "Tipo de resíduo",
          value: "residueType"
        },
        {
          text: "Subtipo do resíduo",
          value: "residueSubtype"
        },
        {
          text: "Quantidade (kg)",
          value: "weight"
        }
      ]
    }),
    async mounted() {
      await this.fetchDestinations();
    },
    methods: {
      ...mapActions(["toggleSnackbar"]),
      ...mapActions("stock/destination", ["getDestinations"]),
      async fetchDestinations() {
        try {
          const payload = this.getPayloadData();
          const { data, meta } = await this.getDestinations({
            id: this.id,
            data: payload
          });
          this.destinations = data;
          this.setTableInfo(meta);
        } catch (error) {
          this.toggleSnackbar({
            text: error?.data?.message ?? "Ocorreu um erro. Tente novamente",
            type: "error"
          });
        }
      },
      async updateData() {
        await this.fetchDestinations();
      }
    },
    filters: {
      formatDonationName(createdAt) {
        const formatedDate = format(new Date(createdAt), "dd/MM/yyyy - HH:mm");
        return `Destinação realizada em ${formatedDate}`;
      }
    }
  };
</script>

<style lang="scss" scoped>
  .content {
    min-height: 510px;
  }
</style>
