<template>
  <v-container v-if="recycler">
    <title-header-action
      btnLabel="Detalhes reciclador"
      :hasActionPermission="canDetailRecycler"
      :btnAction="goToDetailRecycler"
      :title="recycler.name"
    />
    <v-row>
      <v-col cols="12" lg="4">
        <info-recycler :recycler="recycler" />
      </v-col>
      <v-col cols="12" lg="8">
        <list-destination :id="recyclerId"></list-destination>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import { mapActions } from "vuex";

  import InfoRecycler from "@/modules/stock/destination/views/components/InfoRecycler";
  import ListDestination from "@/modules/stock/destination/views/components/ListDestination";
  import TitleHeaderAction from "@/modules/core/views/components/TitleHeaderAction";

  import PermissionMixin from "@/modules/core/mixins/permissionMixin";

  export default {
    name: "DetailRecycler",
    mixins: [PermissionMixin],
    components: {
      InfoRecycler,
      ListDestination,
      TitleHeaderAction
    },
    data: () => ({
      recyclerId: null,
      recycler: null
    }),
    async mounted() {
      this.initializeRecyclerId();
      await this.fetchRecycler();
    },
    computed: {
      canDetailRecycler() {
        return this.userHasRoutePermission("recyclerDetail");
      }
    },
    methods: {
      ...mapActions(["toggleSnackbar"]),
      ...mapActions("stock/destination", ["getRecycler"]),
      initializeRecyclerId() {
        this.recyclerId = parseInt(this.$route.params.id, 10);
      },
      async fetchRecycler() {
        try {
          const { data } = await this.getRecycler(this.recyclerId);
          this.recycler = data;
        } catch (error) {
          this.toggleSnackbar({
            text: error?.data?.message ?? "Ocorreu um erro. Tente novamente",
            type: "error"
          });
        }
      },
      goToDetailRecycler() {
        this.$router.push({
          name: "recyclerDetail",
          params: { id: this.recyclerId }
        });
      }
    }
  };
</script>

<style lang="scss" scoped></style>
